<template>
	<div>
		<a-spin :spinning="loading">
			<span @click="onShowModal">
				<slot>
					<a-button type="primary">选择权益</a-button>
					<span v-if="selectedRowKeys.length !== 0"> 已选{{selectedRowKeys.length}}权益</span>
				</slot>
			</span>
			<a-modal v-model:visible="visible" width="1000px" title="选择权益" :maskClosable="false" @cancel="onCancel" @ok="onOk">
				<a-spin :spinning="loading">
					<div>
						<a-form ref="form" name="form" :model="formState" @finish="onSearch">
							<a-row>
								<a-col :span="18" style="padding-top: 10px;">
									<a-row>
										<a-form-item label="权益名称" name="name" class="ui-form__item">
											<a-input v-model:value="formState.name" placeholder="请输入权益名称"></a-input>
										</a-form-item>
										
										<a-form-item label="权益类型" name="rightType" class="ui-form__item">
											<a-select v-model:value="formState.rightType" placeholder="请选择权益类型" allow-clear style="width: 200px;">
												<a-select-option :value="1">赠送优惠券</a-select-option>
												<a-select-option :value="2">赠送积分</a-select-option>
												<a-select-option :value="3">赠送免费退改签次数</a-select-option>
												<a-select-option :value="4">生日加倍成长值</a-select-option>
												<a-select-option :value="5">活动资格</a-select-option>
												<a-select-option :value="6">赠送一卡通</a-select-option>
												<a-select-option :value="7">赠送商品</a-select-option>
												<a-select-option :value="8">会员升级加速</a-select-option>
												<a-select-option :value="9">会员专属座位</a-select-option>
												<a-select-option :value="10">优惠购票</a-select-option>
											</a-select>
										</a-form-item>
									</a-row>
								</a-col>
								<a-col :span="6" style="text-align: right;">
									<a-button type="primary" html-type="submit">搜索</a-button>
									<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
								</a-col>
							</a-row>
						</a-form>

						<div>
							<a-table :columns="columns" :dataSource="dataList" rowKey="id"
								:pagination="pagination"
								:rowSelection="{ type: type, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
								:scroll="{ x: 500 }"
							>
								<template #bodyCell="{ column, record }">
									<template v-if="column.key === 'rightType'">
										<div>{{ ['赠送优惠券', '赠送积分', '赠送免费退改签次数', '生日加倍成长值', '活动资格', '赠送一卡通', '赠送商品', '会员升级加速', '会员专属座位','优惠购票'][record.rightType - 1] }}</div>
									</template>
									<template v-if="column.key === 'memberType'">
										{{ ['普通会员', '特色会员'][record.memberType - 1] }}
									</template>
								</template>
							</a-table>
						</div>
					</div>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import {
		getMemberRightList
	} from "@/service/modules/member";
	export default {
		model: {
			prop: 'value'
		},
		props: {
			value: {
				type: Array,
				default: () => {
					return []
				}
			},
			selectItem: {
				type: Array,
				default: () => {
					return []
				}
			},
			type: { // checkbox or radio
				type: String,
				default: 'checkbox'
			},
			id: { // 影院ID
				type: null,
				default: undefined
			},
			disabled: {
				type: Boolean,
				default: false
			},
			memberType: {
				type: Number,
				default: 1
			}
		},
		watch: {
			value(newVal) {
				this.selectedRowKeys = newVal;
			},
			// selectItem(newVal) {
			// 	this.selectedListData = JSON.parse(JSON.stringify(newVal));
			// }
		},
		created() {
			this.selectedRowKeys = this.value;
		},
		data() {
			return {
				formState: {},
				targetKeys: [],
				loading: false,
				visible: false,
				selectedRowKeys: [], // 选择的id集合
				selectedListData: [], // 选择的数据集合
				selectedListIdDataMap: {}, // 每选中一项时的数据对比字典，防止分页后数据丢失
				dataList: [],
				columns: [{
					title: '权益类型',
					key: 'rightType',
				}, {
					title: '权益名称',
					dataIndex: 'name',
				}, 
				// {
				// 	title: '会员类型',
				// 	key: 'memberType'
				// },
				],
				modelRef: {},
				searchData: {},
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		methods: {
			async getData() {
				this.loading = true;
				try {
					let ret = await getMemberRightList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						// memberType: this.memberType,
						isDisabled: 0,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						ret.data.list.forEach(item => {
							item.id = item.id;
						})
						this.dataList = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onShowModal() {
				if (this.disabled) return;
				this.getData();
				this.visible = true;
				this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
				this.selectedListData = JSON.parse(JSON.stringify(this.selectItem));
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			onSelectChange(record, selected) {
				if (this.type === 'checkbox') {
					if (selected) {
						this.selectedListData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
						this.selectedListData = this.selectedListData.filter(item => {
							return item.id !== record.id;
						});
					}
				} else {
					if (selected) {
						this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [record.id];
					} else {
						this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [];
					}
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedListData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			resetBtn() {
				this.$refs.formRef.resetFields();
				this.onSearch();
			},
			onCancel() {
				this.visible = false;
				this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
				this.selectedListData = JSON.parse(JSON.stringify(this.selectItem));
			},
			onOk() {
				this.$emit('update:value', JSON.parse(JSON.stringify(this.selectedRowKeys)));
				this.$emit('change', JSON.parse(JSON.stringify(this.selectedListData)));
				this.visible = false;
			},
			onChange(e) {
				this.selectedRowKeys = [];
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 20px;
	}
</style>