<template>
	<div class="detail">
		<Header :title="isEdit ? '编辑会员等级' : '新增会员等级'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 20px;" ref="formRef" :model="modelRef" scrollToFirstError name="formRef" :labelCol="{span: 4 }"
				:wrapperCol="{span: 18 }" @finish="onSubmit">

				<a-form-item label="等级名称" name="name" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input v-model:value="modelRef.name" :disabled="isSee || memberType === 2" placeholder="请输入"></a-input>
				</a-form-item>

				<div v-if="memberType === 1">
					<a-form-item label="等级排序" name="level" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input-number v-model:value="modelRef.level" :min="0" :precision="0" :disabled="isSee" placeholder="请输入"></a-input-number>
					</a-form-item>

					<a-form-item label="所需积分" name="needGrowth" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input-number v-model:value="modelRef.needGrowth" :min="0" :precision="0" :disabled="isSee" placeholder="请输入"></a-input-number>
					</a-form-item>

					<a-form-item label="等级权益" name="commonMemberRightIdList" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-form-item-rest>
							<selectEquity v-model:value="modelRef.commonMemberRightIdList" :memberType="memberType" :selectItem="list" :disabled="isSee" @change="(dataList)=> list = dataList"></selectEquity>
						</a-form-item-rest>

						<a-table v-if="list.length" rowKey="id" :columns="columns" :dataSource="list" :pagination="false">
							<template #bodyCell="{ column, record, index }">
								<template v-if="column.key === 'rightType'">
									<div>{{ ['赠送优惠券', '赠送积分', '赠送免费退改签次数', '生日加倍成长值', '活动资格', '赠送一卡通', '赠送商品', '会员升级加速', '会员专属座位','优惠购票'][record.rightType - 1] }}</div>
								</template>
								<template v-if="column.key === 'memberType'">
									{{ ['普通会员', '特色会员'][record.memberType - 1] }}
								</template>
								<template v-if="column.key === 'action'">
									<a-button type="link" danger size="small" :disabled="isSee" @click="onDelete(record, index)">移除</a-button>
								</template>
							</template>
						</a-table>
					</a-form-item>
				</div>
				<div v-if="memberType === 2">
					<a-form-item name="price" label="价格" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input-number v-model:value="modelRef.price" :min="0" :precision="2" placeholder="请输入"></a-input-number> 元
					</a-form-item>

					<a-form-item name="joinMemberFlag" label="入会礼遇" :rules="[{ required: true, message: '必选项不允许为空' }]" extra="不配置[入会礼遇图片]则默认使用权益图标展示">
						<a-switch v-model:checked="modelRef.joinMemberFlag" :unCheckedValue="0" :checkedValue="1"></a-switch>
						<div v-if="modelRef.joinMemberFlag" style="margin-top: 20px;">
							<a-form-item-rest>
								<selectEquity v-model:value="modelRef.joinMemberRightIdList" :memberType="memberType" :selectItem="joinList" :disabled="isSee" @change="(dataList)=> joinList = dataList"></selectEquity>
							
								<a-table v-if="joinList.length" rowKey="id" :columns="columns2" :dataSource="joinList" :pagination="false">
									<template #bodyCell="{ column, record, index }">
										<template v-if="column.key === 'rightType'">
											<div>{{ ['赠送优惠券', '赠送积分', '赠送免费退改签次数', '生日加倍成长值', '活动资格', '赠送一卡通', '赠送商品', '会员升级加速', '会员专属座位','优惠购票'][record.rightType - 1] }}</div>
										</template>
										<template v-if="column.key === 'imageUrl'">
											<a-upload list-type="picture-card"
												:show-upload-list="false"
												action="/admin/ajaxUpload.do" accept="image/*"
												:disabled="isSee"
												@change="(file) => onUploadChange(file, index, 'joinList')"
											>
												<div v-if="!record.cinemaImage">
													<Icon icon="PlusOutlined"></Icon>
													<div style="margin-top: 8px">Upload</div>
												</div>
												<div v-else>
													<a-image :src="record.cinemaImage"></a-image>
												</div>
											</a-upload>
										</template>
										<template v-if="column.key === 'action'">
											<a-button type="link" danger size="small" :disabled="isSee" @click="onDelete(record, index, 'joinList')">移除</a-button>
										</template>
									</template>
								</a-table>
							</a-form-item-rest>
						</div>
					</a-form-item>

					<a-form-item name="rightSettingFlag" label="权益配置" :rules="[{ required: true, message: '必选项不允许为空' }]" extra="不配置[影院服务图片]则默认使用权益图标展示">
						<a-switch v-model:checked="modelRef.rightSettingFlag" :unCheckedValue="0" :checkedValue="1"></a-switch>

						<div v-if="modelRef.rightSettingFlag" style="margin-top: 20px;">
							<a-form-item-rest>
								<selectEquity v-model:value="modelRef.rightSettingRightIdList" :memberType="memberType" :selectItem="rightList" :disabled="isSee" @change="(dataList)=> rightList = dataList"></selectEquity>
								
								<a-table v-if="rightList.length" rowKey="id" :columns="columns1" :dataSource="rightList" :pagination="false">
									<template #bodyCell="{ column, record, index }">
										<template v-if="column.key === 'rightType'">
											<div>{{ ['赠送优惠券', '赠送积分', '赠送免费退改签次数', '生日加倍成长值', '活动资格', '赠送一卡通', '赠送商品', '会员升级加速', '会员专属座位','优惠购票'][record.rightType - 1] }}</div>
										</template>
										<template v-if="column.key === 'memberType'">
											{{ ['普通会员', '特色会员'][record.memberType - 1] }}
										</template>
										<template v-if="column.key === 'cinemaFlag'">
											<a-switch v-model:checked="record.cinemaFlag" :unCheckedValue="0" :checkedValue="1"></a-switch>
										</template>
										<template v-if="column.key === 'imageUrl'">
											<a-upload list-type="picture-card"
												:show-upload-list="false"
												action="/admin/ajaxUpload.do" accept="image/*"
												:disabled="isSee"
												@change="(file) => onUploadChange(file, index, 'rightList')"
											>
												<div v-if="!record.cinemaImage">
													<Icon icon="PlusOutlined"></Icon>
													<div style="margin-top: 8px">Upload</div>
												</div>
												<div v-else>
													<a-image :src="record.cinemaImage"></a-image>
												</div>
											</a-upload>
										</template>
										<template v-if="column.key === 'action'">
											<a-button type="link" danger size="small" :disabled="isSee" @click="onDelete(record, index, 'rightList')">移除</a-button>
										</template>
									</template>
								</a-table>
							</a-form-item-rest>
						</div>
					</a-form-item>
				</div>

				<a-form-item style="text-align: center;margin-top: 20px;">
					<a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
					<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
				</a-form-item>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import {Icon} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import selectEquity from '@/components/select/selectEquity/index.vue';
	import { getMemberLevelDetail, saveMemberLevel, updateMemberLevel } from '@/service/modules/member.js';
	export default {
		name: "evaluateDetail",
		components: {
			Icon,
			Header,
			selectEquity,
		},
		props: {
			id: {
				type: Number,
				default: 0
			},
			isEdit: {
				type: Boolean,
				default: false
			},
			isSee: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				memberType: 1,
				label: '选择优惠券',
				button: '选择优惠券',
				loading: false,
				modelRef: {
					commonMemberRightIdList: []
				},
				columns: [{
					title: '权益类型',
					key: 'rightType',
				}, {
					title: '权益名称',
					dataIndex: 'name',
				}, {
					title: '图片',
					key: 'imageUrl'
				}, {
					title: '操作',
					key: 'action',
					width: 50
				}],
				columns1: [{
					title: '权益类型',
					key: 'rightType',
					width: 180
				}, {
					title: '权益名称',
					dataIndex: 'name',
				}, {
					title: '影院服务展示',
					key: 'cinemaFlag',
					width: 120
				}, {
					title: '影院服务图片',
					key: 'imageUrl'
				}, {
					title: '操作',
					key: 'action',
					width: 50
				}],
				columns2: [{
					title: '权益类型',
					key: 'rightType',
					width: 180
				}, {
					title: '权益名称',
					dataIndex: 'name',
				}, {
					title: '入会礼遇图片',
					key: 'imageUrl'
				}, {
					title: '操作',
					key: 'action',
					width: 50
				}],
				list: [],
				joinList: [],
				rightList: []
			};
		},
		created() {
			if (this.id) {
				this.getData();
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				let ret;
				if (this.joinList.length) {
					postData.joinMemberRightList = this.joinList.map(item => {
						return {
							id: item.id,
							cinemaImage: item.cinemaImage
						}
					})
				}
				if (this.rightList.length) {
					postData.rightSettingRightList = this.rightList.map(item => {
						return {
							id: item.id,
							cinemaFlag: item.cinemaFlag,
							cinemaImage: item.cinemaImage
						}
					})
				}
				try {
					this.loading = true;
					if (this.isEdit && this.id) {
						ret = await updateMemberLevel(postData);
					} else {
						ret = await saveMemberLevel(postData);
					}
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功！');
						this.onBack(true);
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getData(){
				this.loading = true;
				let ret = await getMemberLevelDetail({
					id: this.id
				})
				this.loading = false;
				if (ret.code === 200) {
					if (ret.data.name === '特色会员') {
						this.memberType = 2;
						this.joinList = ret.data.joinMemberRightList || [];
						this.rightList = ret.data.rightSettingRightList || [];
						ret.data.joinMemberRightIdList = this.joinList.map(item => {
							return item.id;
						})
						ret.data.rightSettingRightIdList = this.rightList.map(item => {
							return item.id;
						})
					} else {
						this.memberType = 1;
						this.list = ret.data.commonMemberRightList || [];
						ret.data.commonMemberRightIdList = this.list.map(item => {
							return item.id;
						})
					}
					delete ret.data.joinMemberRightList;
					delete ret.data.rightSettingRightList;
					delete ret.data.commonMemberRightList;
					this.modelRef = ret.data;
				}
			},
			onDelete(item, index, key) {
				if (key === 'joinList') {
					let i = this.modelRef.joinMemberRightIdList.indexOf(item.id);
					this.modelRef.joinMemberRightIdList.splice(i, 1);
					this.joinList.splice(index, 1);
				} else if(key === 'rightList') {
					let i = this.modelRef.rightSettingRightIdList.indexOf(item.id);
					this.modelRef.rightSettingRightIdList.splice(i, 1);
					this.rightList.splice(index, 1);
				} else {
					let i = this.modelRef.commonMemberRightIdList.indexOf(item.id);
					this.modelRef.commonMemberRightIdList.splice(i, 1);
					this.list.splice(index, 1);
				}
			},
			onUploadChange(info, index, key) {
				if (info.file.status === 'done') {
					info.fileList.forEach(file => {
						if (file.response) {
							this[key][index].cinemaImage = file.response.data.imgUrl;
						}
					});
				}
			},
		}
	};
</script>

<style lang="less" scoped>
	.detail {
		.ui-title {
			position: relative;
			margin: 20px 0 10px;
			padding-left: 10px;
			font-size: 16px;
			font-weight: 600;
		}

		.ui-title::before {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			width: 4px;
			height: 20px;
			border-radius: 2px;
			background-color: #1890ff;
			transform: translateY(-50%);
		}
	}
</style>